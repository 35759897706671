import { memo } from 'react';

import { Button } from '@/components/atoms/button';
import { smoothScrollToElementById } from '@/utils/scroll-to';

import type { FC } from 'react';

type ResumeCreateButtonProps = { isPc: boolean };

const _ResumeCreateButton: FC<ResumeCreateButtonProps> = ({ isPc }) => {
  const onScrollBtnPressed = () => {
    smoothScrollToElementById('scroll-target', -50);
  };

  return (
    <Button
      type='button'
      styleTypes='primary'
      width='100%'
      height='100%'
      padding={isPc ? '24px' : '18px'}
      fontSize={isPc ? 'SIZE_24' : 'SIZE_18'}
      fontWeight='SEMI_BOLD'
      lineHeight='HEIGHT_27'
      letterSpace='LITTLE_WIDE'
      onBtnPressed={onScrollBtnPressed}
    >
      今すぐ履歴書を作成する
    </Button>
  );
};

/** 今すぐ履歴書を作成するボタン */
export const ResumeCreateButton = memo(_ResumeCreateButton);
