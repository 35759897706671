import styled from '@emotion/styled';

import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';
import { mq } from '@/styles/media';

export const DivRootPadding = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 16px;

  ${mq.tablet} {
    justify-content: center;
  }

  ${mq.sp} {
    gap: 16px;
    justify-content: center;
  }
`;

export const ArtRootResumeCard = styled.article`
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  // PCの場合は「最大3カラム」で表示する
  width: 31%;
  height: 100%;

  // TABLETの場合は「最大2カラム」で表示する
  ${mq.tablet} {
    width: 45%;
    height: 100%;
  }

  // SPの場合は「最大2カラム」で表示する
  ${mq.sp} {
    width: 47%;
    height: 100%;
  }
`;

export const SecContentWrapper = styled.section`
  display: grid;
  grid-gap: 16px;
  padding: 16px;

  ${mq.sp} {
    grid-gap: 8px;
    padding: 8px;
  }
`;

export const ParTitle = styled.h2`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: ${FONT_SIZE.SIZE_20};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${mq.sp} {
    font-size: ${FONT_SIZE.SIZE_14};
  }
`;

export const ParDescription = styled.p`
  height: 84px;
  max-height: 84px;

  ${mq.sp} {
    height: 64px;
    max-height: 64px;
    font-size: ${FONT_SIZE.SIZE_12};
  }
`;

export const ButtonSelect = styled.a`
  border-radius: 3px;
  background-color: ${(props) => (props.type === 'resume' ? '#048573' : '#FF7F50')};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: ${FONT_WEIGHT.BOLD};
`;
export const ButtonText = styled.p``;

export const ButtonFilterItem = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 20px;
  &:hover {
    background-color: #ccc;
  }
  &.active {
    background-color: #048573;
    color: white;
  }
`;

export const DivButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
