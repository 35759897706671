import dynamic from 'next/dynamic';
import Link from 'next/link';

import { Heading } from '@/components/atoms/heading';
import { Spacer } from '@/components/atoms/spacer';
import { MainLayoutWithFooter } from '@/components/layouts/main-layout/with-footer';
import { useResumeCardInfo } from '@/components/templates/top-template/use-resume-card-info';
import { Axis } from '@/consts/axis';
import { useMatchMedia } from '@/hooks/use-match-media';
import { useSelectResume } from '@/hooks/use-select-resume';

import type { Category } from '@/mediaApi/types';
import type { FC } from 'react';

import { ResumeCard } from './resume-card';
import { ResumeCreateButton } from './resume-create-button';
import { ResumeCreatingFlowCards } from './resume-creating-flow-cards';
import * as Styled from './style';

const TopSection = dynamic(
  () => import('@/components/templates/top-template/top-section').then((modules) => modules.TopSection),
  { ssr: false },
);

const HowToUse = dynamic(
  () => import('@/components/templates/top-template/HowToUse').then((modules) => modules.HowToUse),
  { ssr: false },
);

type Props = {
  categories: Category[];
};

export const TopTemplate: FC<Props> = ({ categories }) => {
  const { isPcSize: isPc } = useMatchMedia();
  const selectResume = useSelectResume();

  const { resumeCardInfo } = useResumeCardInfo();

  const DivStepsHeadingContainer = isPc ? Styled.DivStepsHeadingContainer : Styled.SpDivStepsHeadingContainer;
  const fontSize = isPc ? 'SIZE_20' : 'SIZE_16';
  const marginRight = !isPc ? '2px' : undefined;
  const marginLeft = !isPc ? '2px' : undefined;

  // TODO : 以下のコンポーネントの実装はリファクタリングが必要。PC と Tablet と SP の場合を考慮した実装に変更する必要がある
  // TODO : 具体的には useMatchMedia で isPc を使用した分岐処理をメディアクエリを使用した分岐処理に変更する
  return (
    <MainLayoutWithFooter categories={categories}>
      <TopSection onBtnPressed={() => selectResume.setResumeAndPreferences('JIS_RESUME_TITLE')} />

      <Styled.SecHeadingWrapper id='scroll-target'>
        <Styled.HeadingWrapper>
          <Styled.ParTemplateText>テンプレートを選んで始めよう！</Styled.ParTemplateText>
        </Styled.HeadingWrapper>
      </Styled.SecHeadingWrapper>

      <Styled.SecCardsWrapper>
        <ResumeCard resumeCardInfo={resumeCardInfo} />
      </Styled.SecCardsWrapper>

      <DivStepsHeadingContainer>
        <Heading
          type='p'
          text='このサービスが提供する'
          fontSize={isPc ? 'SIZE_20' : fontSize}
          style={{ letterSpacing: '1.28px', marginRight }}
        />
        <span className='stepsTitle' style={{ letterSpacing: '1.28px' }}>
          3
        </span>
        <Heading
          type='p'
          text='つの”らくらく”'
          fontSize={isPc ? 'SIZE_20' : fontSize}
          style={{ letterSpacing: '1.28px', marginLeft }}
        />
      </DivStepsHeadingContainer>

      <Spacer axis={Axis.VERTICAL} size={50} />
      <HowToUse />

      <Styled.DivToCreateScrollBtnWrapper>
        <ResumeCreateButton isPc={isPc} />
      </Styled.DivToCreateScrollBtnWrapper>

      <Spacer axis={Axis.VERTICAL} size={30} />

      <Styled.DivFlowHeadingContainer>
        <Styled.HeadingWrapper>
          <Heading type='h2' text='作成の流れ' fontSize='SIZE_20' />
        </Styled.HeadingWrapper>
      </Styled.DivFlowHeadingContainer>

      <Spacer axis={Axis.VERTICAL} size={30} />

      <Styled.DivResumeCreatingFlowCardsContainer>
        <ResumeCreatingFlowCards />
      </Styled.DivResumeCreatingFlowCardsContainer>

      <Styled.InternalLinkWrapper>
        <Link style={{ cursor: 'pointer' }} href='/how-to-use'>
          詳しい使い方はこちら
        </Link>
      </Styled.InternalLinkWrapper>
      <Spacer axis={Axis.VERTICAL} size={30} />

      <Styled.DivToCreateScrollBtnWrapper>
        <ResumeCreateButton isPc={isPc} />
      </Styled.DivToCreateScrollBtnWrapper>

      <Spacer axis={Axis.VERTICAL} size={30} />
    </MainLayoutWithFooter>
  );
};
