import { HeadTemplate } from '@/components/templates/head-template';
import { TopTemplate } from '@/components/templates/top-template';
import { CANONICAL_URL } from '@/consts/canonical-url';
import { TOP_JSON_LD } from '@/consts/json-ld';
import { PAGE_FULL_TITLE } from '@/consts/meta';
import { getCategories } from '@/mediaApi';

import type { CategoriesProps } from '@/types/categories-props';
import type { GetStaticProps, NextPage } from 'next';

const TopPage: NextPage<CategoriesProps> = ({ categories }) => (
  <>
    <HeadTemplate
      pageTitle={`${PAGE_FULL_TITLE}`}
      pageCanonicalUrl={CANONICAL_URL.TOP}
      isNoIndex={false}
      jsonLd={TOP_JSON_LD}
    />

    <TopTemplate categories={categories} />
  </>
);

export const getStaticProps: GetStaticProps<CategoriesProps> = async () => ({
  props: { categories: await getCategories() },
});

export default TopPage;
