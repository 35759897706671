import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';

import type { HeadingProps } from '@/components/atoms/heading';
import type { FontSizeKeyType } from '@/styles/font-size';
import type { FontWeightKeyType } from '@/styles/font-weight';

const StyledHeading = styled.h2<
  Omit<HeadingProps, 'type' | 'text'> & { fontSize: FontSizeKeyType; fontWeight: FontWeightKeyType }
>`
  color: ${COLOR.BLACK};
  font-weight: ${({ fontWeight }) => FONT_WEIGHT[fontWeight]};
  font-size: ${({ fontSize }) => FONT_SIZE[fontSize]};
`;

export { StyledHeading as Heading };
