import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';

import type { CircleProps } from '@/components/atoms/circle';

export const DivCircle = styled.div<CircleProps>`
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => COLOR[backgroundColor]};
  color: ${({ fontColor }) => COLOR[fontColor]};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-size: ${({ fontSize }) => FONT_SIZE[fontSize]};
  font-weight: ${({ fontWeight }) => FONT_WEIGHT[fontWeight]};
  display: flex;
  align-items: center;
  justify-content: center;
`;
