import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';
import { LINE_HEIGHT } from '@/styles/line-height';

export const DivResumeCreatingFlowCard = styled.div`
  border: 1px solid ${COLOR.GREEN400};
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  max-width: 316px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 980px) {
    max-width: none;
    margin-bottom: 16px;
  }
`;

export const DivCardTitle = styled.h3`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: ${FONT_SIZE.SIZE_16};
`;

export const DivCardTitleUnderline = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${COLOR.GREEN400};
  opacity: 0.5;
  margin-top: -8px;
`;

export const DivCardContent = styled.div`
  font-weight: ${FONT_WEIGHT.NORMAL};
  font-size: ${FONT_SIZE.SIZE_14};
  line-height: ${LINE_HEIGHT.HEIGHT_21};
`;
