import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { FONT_WEIGHT } from '@/styles/font-weight';
import { mq } from '@/styles/media';

const SectionPositionRelative = styled.section`
  position: relative;
  padding-bottom: 32px;
  ${mq.pc} {
    padding-bottom: 40px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const ParTemplateText = styled.h2`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: ${FONT_SIZE.SIZE_20};

  ${mq.sp} {
    font-size: ${FONT_SIZE.SIZE_16};
  }
`;

const HeadingAbsolute = styled(SectionPositionRelative)`
  position: absolute;
  // MEMO : next/image よりも上に表示させるため
  z-index: 1;
  top: 80px;
  left: 80px;
`;

export const AbsoluteRight = styled(HeadingAbsolute)`
  right: 100px;
  top: 100px;
  left: auto;

  @media (min-width: 768px) and (max-width: 1024px) {
    /* Adjustments for Tablet */
    right: 75px;
    top: 75px;
  }
`;

export const AbsoluteLeft = styled(HeadingAbsolute)`
  left: 180px;
  top: 100px;

  @media (min-width: 768px) and (max-width: 1024px) {
    /* Adjustments for Tablet */
    left: 175px;
    top: 75px;
  }
`;

export const SecHeadingWrapper = styled.section`
  text-align: center;
`;

export const DivStepsHeadingContainer = styled.div`
  text-align: center;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  letter-spacing: 1.14px;
  white-space: nowrap;

  & > * {
    display: inline-block;
  }

  .stepsTitle {
    color: #048573;
    font-size: 30px;
  }
`;

export const SpDivStepsHeadingContainer = styled(DivStepsHeadingContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1.5%;
`;

export const StepsCardsContainer = styled.div`
  position: relative;
  background: var(--cream-2, #fef4e4);
  width: 100%;
  height: 415px;
  display: flex;
`;

const SharedStyles = `
  color: #048573;
  font-family: 'Hiragino Sans';
  font-weight: 700;
`;

export const DivStepTitle = styled.div`
  ${SharedStyles}
  font-size: 32px;
`;

export const SpDivStepsTitle = styled.div`
  ${SharedStyles}
  font-size: 26px;
  text-align: center;
`;

export const SpDivSubTitle = styled.div`
  text-align: center;
`;

const SharedDescriptionStyles = `
  font-family: 'Hiragino Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StepDescription = styled.p`
  ${SharedDescriptionStyles}
  font-size: 16px;
  margin-left: 1ch;
`;

export const SpStepDescription = styled.p`
  ${SharedDescriptionStyles}
  font-size: 12px;
  display: flex;
  justify-content: center;
  text-align: left;
`;

const SharedImgStyles = `
  height: auto;
`;

export const StepsImg = styled.img`
  ${SharedImgStyles}
  width: 95%;
`;

export const SpStepsImg = styled.img`
  ${SharedImgStyles}
  position: relative;
  width: 100%;
  z-index: -1;
`;

export const DivFlowHeadingContainer = styled.div`
  text-align: center;
`;

export const HeadingWrapper = styled.div`
  display: inline-block;
  text-align: center;
  border-bottom: solid 2px ${COLOR.GREEN400};
`;

export const DivResumeCreatingFlowCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 980px;
  margin: 0 auto 50px auto;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    padding: 16px 16px 0 16px;
    margin: 0 auto;
  }
`;

export const DivToCreateScrollBtnWrapper = styled.div`
  ${mq.pc} {
    display: none;
  }

  display: block;
  margin: 70px auto 50px auto;
  padding: 0 16px;

  @media screen and (max-width: 980px) {
    margin-bottom: 16px;
  }

  @media screen and (min-width: 980px) {
    max-width: 688px;
  }
`;

export const InternalLinkWrapper = styled.div`
  text-decoration: underline;
  text-align: right;
  right: 60px;
  position: relative;
  bottom: 40px;

  @media screen and (max-width: 768px) {
    text-align: center;
    bottom: 0px;
    right: 0px;
  }
`;

export const SecCardsWrapper = styled.section`
  ${mq.pc} {
    padding: 25px 0 40px 0;
  }
`;
