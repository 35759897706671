import { forwardRef, memo } from 'react';

import type { FontSizeKeyType } from '@/styles/font-size';
import type { FontWeightKeyType } from '@/styles/font-weight';
import type { Ref, CSSProperties, ElementType } from 'react';

import * as Styled from './style';

type HeadingElement =
  | JSX.IntrinsicElements['h1']
  | JSX.IntrinsicElements['h2']
  | JSX.IntrinsicElements['h3']
  | JSX.IntrinsicElements['h4']
  | JSX.IntrinsicElements['h5'];

export type HeadingProps = {
  type?: ElementType<HeadingElement>;
  text: string | string[];
  fontSize?: FontSizeKeyType;
  fontWeight?: FontWeightKeyType;
  style?: CSSProperties;
};

// textが配列の場合、その各要素をPタグとして表示し、そうでない場合はtextをそのまま表示
const _Heading = (
  { text, fontSize = 'SIZE_20', fontWeight = 'BOLD', style, type = 'h1' }: HeadingProps,
  ref: Ref<HTMLHeadingElement>,
) => (
  <Styled.Heading as={type} style={style} ref={ref} fontSize={fontSize} fontWeight={fontWeight}>
    {Array.isArray(text) ? text.map((t, index) => <p key={index}>{t}</p>) : text}
  </Styled.Heading>
);

export const Heading = memo(forwardRef(_Heading));
