import { memo } from 'react';

import type { ColorKeyType } from '@/styles/color';
import type { FontSizeKeyType } from '@/styles/font-size';
import type { FontWeightKeyType } from '@/styles/font-weight';
import type { FC } from 'react';

import * as Styled from './style';

export type CircleProps = {
  content: string;
  width: string;
  height: string;
  backgroundColor: ColorKeyType;
  fontColor: ColorKeyType;
  fontSize: FontSizeKeyType;
  fontWeight: FontWeightKeyType;
};

const _Circle: FC<CircleProps> = ({ content, width, height, backgroundColor, fontColor, fontSize, fontWeight }) => (
  <Styled.DivCircle
    content={content}
    width={width}
    height={height}
    backgroundColor={backgroundColor}
    fontColor={fontColor}
    fontSize={fontSize}
    fontWeight={fontWeight}
  >
    {content}
  </Styled.DivCircle>
);

export const Circle = memo(_Circle);
