import { forwardRef, memo } from 'react';

import type { Ref, SVGProps } from 'react';

const CheckCircle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M12 22c5.47 0 10-4.54 10-10 0-5.47-4.54-10-10.01-10C6.53 2 2 6.53 2 12c0 5.46 4.54 10 10 10Zm-1.098-5.206c-.333 0-.608-.137-.863-.48l-2.46-3.02a1.065 1.065 0 0 1-.236-.637c0-.441.343-.804.784-.804.285 0 .5.088.746.412l1.99 2.568 4.186-6.725c.186-.294.441-.451.696-.451.432 0 .833.294.833.755 0 .215-.127.44-.245.647l-4.607 7.255c-.206.323-.49.48-.824.48Z'
      fill='#20C0AA'
    />
  </svg>
);

export const IconCheckCircle = memo(forwardRef(CheckCircle));
