import { useMemo } from 'react';

import { RESUME_TITLE } from '@/consts/resume-title';
import { useSelectCv } from '@/hooks/use-select-cv';
import { useSelectResume } from '@/hooks/use-select-resume';

import type { ResumeCardInfo } from '@/components/templates/top-template/type';

const generateCVCardInfo = (
  resumeTitle: string,
  resumeDescription: string,
  onBtnPressed: () => Promise<void>,
  resumeFormatUrl: string,
): ResumeCardInfo => ({
  resumeTitle,
  resumeDescription,
  resumePackageImageUrl: '/images/shokumukeireki.webp',
  resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
  onBtnPressed,
  resumeFormatUrl,
  type: 'cv',
});

export const useResumeCardInfo = () => {
  const selectResume = useSelectResume();

  const selectCv = useSelectCv();

  const resumeCardInfo = useMemo(
    (): ResumeCardInfo[] => [
      {
        resumeTitle: RESUME_TITLE.JIS_RESUME_TITLE,
        resumeDescription: '就職活動や転職活動に使える一般的な履歴書です',
        resumePackageImageUrl: '/images/resume-1.webp',
        resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
        onBtnPressed: selectResume.onSelectJisResume,
        resumeFormatUrl: '/format/jis-resume',
        type: 'resume',
      },
      {
        resumeTitle: RESUME_TITLE.MINISTRY_OF_HEALTH_RESUME_TITLE,
        resumeDescription: 'Eメールや通勤時間、扶養家族、配偶者が削除された新たな様式の履歴書です',
        resumePackageImageUrl: '/images/ministry-resume.webp',
        resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
        onBtnPressed: selectResume.onSelectMinistryOfHealthResume,
        resumeFormatUrl: '/format/ministry-of-health',
        type: 'resume',
      },
      {
        resumeTitle: RESUME_TITLE.JOB_CHANGE_TITLE,
        resumeDescription: '転職活動に使える自己PR例が豊富な履歴書です',
        resumePackageImageUrl: '/images/resume-1.webp',
        resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
        onBtnPressed: selectResume.onSelectJobResume,
        resumeFormatUrl: '/format/job-change',
        type: 'resume',
      },
      {
        resumeTitle: RESUME_TITLE.NEW_GRADUATE_TITLE,
        resumeDescription: '自己PR欄が広く就活に使える自己PR例が豊富な履歴書です',
        resumePackageImageUrl: '/images/new-graduate-and-intern-resume.webp',
        resumeThreeText: ['A4サイズ2枚', '新卒', '就職活動'],
        onBtnPressed: selectResume.onSelectNewGraduateResume,
        resumeFormatUrl: '/format/new-graduate',
        type: 'resume',
      },
      {
        resumeTitle: RESUME_TITLE.INTERN_TITLE,
        resumeDescription: 'インターンに使える自己PR例が豊富な履歴書です',
        resumePackageImageUrl: '/images/new-graduate-and-intern-resume.webp',
        resumeThreeText: ['A4サイズ2枚', 'インターン', '就職活動'],
        onBtnPressed: selectResume.onSelectInternResume,
        resumeFormatUrl: '/format/intern',
        type: 'resume',
      },
      {
        resumeTitle: RESUME_TITLE.PART_TIME_TITLE,
        resumeDescription: 'アルバイト・パートに使える自己PR例が豊富な履歴書です',
        resumePackageImageUrl: '/images/resume-1.webp',
        resumeThreeText: ['A4サイズ2枚', 'アルバイト', '就職活動'],
        onBtnPressed: selectResume.onSelectPartResume,
        resumeFormatUrl: '/format/part-time',
        type: 'resume',
      },
      {
        resumeTitle: RESUME_TITLE.NOT_ID_PICTURE_TITLE,
        resumeDescription: '証明写真がないJIS規格の履歴書です',
        resumePackageImageUrl: '/images/no-id-photo-resume.webp',
        resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
        onBtnPressed: selectResume.onSelectNoIdPhotoResume,
        resumeFormatUrl: '/format/not-id-picture',
        type: 'resume',
      },
      // {
      //   resumeTitle: RESUME_TITLE.CV_RESUME_TITLE,
      //   resumeDescription: '転職活動に使える職務経歴書です',
      //   resumePackageImageUrl: '/images/shokumukeireki.jpg',
      //   resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
      //   onBtnPressed: selectCv.onSelectShokumuKeireki,
      //   resumeFormatUrl: '/format/cv',
      //   type: 'cv',
      // },
      // {
      //   resumeTitle: RESUME_TITLE.CV_SALES_RESUME_TITLE,
      //   resumeDescription: '転職活動に使える営業用の職務経歴書です',
      //   resumePackageImageUrl: '/images/shokumukeireki.jpg',
      //   resumeThreeText: ['A4サイズ2枚', '社会人', '就職活動'],
      //   onBtnPressed: selectCv.onSelectShokumuKeirekiSales,
      //   resumeFormatUrl: '/format/cv-sales',
      //   type: 'cv',
      // },

      generateCVCardInfo(
        RESUME_TITLE.CV_RESUME_TITLE,
        '転職活動に使える職務経歴書です',
        selectCv.onSelectShokumuKeireki,
        '/format/cv',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_SALES_RESUME_TITLE,
        '転職活動に使える営業用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiSales,
        '/format/cv-sales',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_ENGINEER_RESUME_TITLE,
        '転職活動に使えるソフトウェアエンジニア用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiEngineer,
        '/format/cv-engineer',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_ADMINISTRATION_RESUME_TITLE,
        '転職活動に使える事務職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiAdministration,
        '/format/cv-administration',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_MARKETING_RESUME_TITLE,
        '転職活動に使えるマーケティング用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiMarketing,
        '/format/cv-marketing',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_SERVICE_RESUME_TITLE,
        '転職活動に使える販売・接客職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiService,
        '/format/cv-service',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_CONSULTANT_RESUME_TITLE,
        '転職活動に使えるコンサルタント用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiConsultant,
        '/format/cv-consultant',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_FINANCE_RESUME_TITLE,
        '転職活動に使える金融・証券関連職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiFinance,
        '/format/cv-finance',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_EDUCATION_RESUME_TITLE,
        '転職活動に使える教育職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiEducation,
        '/format/cv-education',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_PR_RESUME_TITLE,
        '転職活動に使える広報・PR職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiPR,
        '/format/cv-pr',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_CREATIVE_RESUME_TITLE,
        '転職活動に使えるクリエイティブ職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiCreative,
        '/format/cv-creative',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_MANUFACTURING_RESUME_TITLE,
        '転職活動に使える製造・生産管理職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiManufacturing,
        '/format/cv-manufacturing',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_LOGISTICS_RESUME_TITLE,
        '転職活動に使える物流・運輸関連職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiLogistics,
        '/format/cv-logistics',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_CUSTOMER_SUPPORT_RESUME_TITLE,
        '転職活動に使えるカスタマーサポート職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiCustomerSupport,
        '/format/cv-customer-support',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_LEGAL_RESUME_TITLE,
        '転職活動に使える法務・コンプライアンス職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiLegal,
        '/format/cv-legal',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_HR_RESUME_TITLE,
        '転職活動に使える人事・採用関連職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiHR,
        '/format/cv-hr',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_ACCOUNTING_RESUME_TITLE,
        '転職活動に使える経理・財務職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiAccounting,
        '/format/cv-accounting',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_RESEARCH_RESUME_TITLE,
        '転職活動に使える研究・開発職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiResearch,
        '/format/cv-research',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_REALESTATE_RESUME_TITLE,
        '転職活動に使える不動産関連職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiRealEstate,
        '/format/cv-real-estate',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_FOOD_RESUME_TITLE,
        '転職活動に使える飲食業関連職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiFood,
        '/format/cv-food',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_CONSTRUCTION_RESUME_TITLE,
        '転職活動に使える施工管理職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiConstruction,
        '/format/cv-construction',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_DRIVER_RESUME_TITLE,
        '転職活動に使えるドライバー職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiDriver,
        '/format/cv-driver',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_CAREGIVER_RESUME_TITLE,
        '転職活動に使える介護職用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiCaregiver,
        '/format/cv-caregiver',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_DENTAL_HYGIENIST_RESUME_TITLE,
        '転職活動に使える歯科衛生士用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiDentalHygienist,
        '/format/cv-dental-hygienist',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_CHILDCARE_RESUME_TITLE,
        '転職活動に使える保育士用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiChildcare,
        '/format/cv-childcare',
      ),
      generateCVCardInfo(
        RESUME_TITLE.CV_NURSE_RESUME_TITLE,
        '転職活動に使える看護師用の職務経歴書です',
        selectCv.onSelectShokumuKeirekiNurse,
        '/format/cv-nurse',
      ),
    ],
    [],
  );

  return {
    resumeCardInfo,
  };
};
