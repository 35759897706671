import React from 'react';
import { useMemo } from 'react';

import { Circle } from '@/components/atoms/circle';
import { Spacer } from '@/components/atoms/spacer';
import { IconDownloadCloud } from '@/components/icons/download-cloud';
import { IconMagnifyingGlass } from '@/components/icons/magnifying-glass';
import { IconPaper } from '@/components/icons/paper';
import { Axis } from '@/consts/axis';
import { getSpacing } from '@/consts/spacingUnit';

import type { ResumeCreatingFlowCardInfo } from './type';

import * as Styled from './style';

export const ResumeCreatingFlowCards = () => {
  const RESUME_CREATING_FLOW_CARD_INFO: ResumeCreatingFlowCardInfo[] = useMemo(
    () => [
      {
        resumeCreatingFlowCardTitle: '履歴書を選ぶ',
        resumeCreatingFlowCardContent: 'こちらから作成したい履歴書のテンプレートを選択します。',
        resumeCreatingFlowCardIcon: <IconMagnifyingGlass />,
      },
      {
        resumeCreatingFlowCardTitle: 'フォームに入力する',
        resumeCreatingFlowCardContent:
          'フォームに従って情報を入力します。回答した情報はブラウザに保存することもできるので、一度入力を中断しても途中から再開可能です。',
        resumeCreatingFlowCardIcon: <IconPaper />,
      },
      {
        resumeCreatingFlowCardTitle: '履歴書をダウンロードまたは印刷する',
        resumeCreatingFlowCardContent:
          '作成した履歴書は、PDFとしてダウンロードしたり、コンビニ印刷したりすることができます。',
        resumeCreatingFlowCardIcon: <IconDownloadCloud />,
      },
    ],
    [],
  );

  return (
    <>
      {RESUME_CREATING_FLOW_CARD_INFO.map((item, idx) => (
        <Styled.DivResumeCreatingFlowCard key={idx}>
          <Circle
            content={`${idx + 1}`}
            width='37px'
            height='37px'
            backgroundColor='GREEN400'
            fontColor='WHITE'
            fontSize='SIZE_16'
            fontWeight='BOLD'
          />

          <Spacer axis={Axis.VERTICAL} size={getSpacing(2)} />

          {item.resumeCreatingFlowCardIcon}

          <Spacer axis={Axis.VERTICAL} size={getSpacing(2)} />

          <Styled.DivCardTitle>
            {item.resumeCreatingFlowCardTitle}
            <Styled.DivCardTitleUnderline />
          </Styled.DivCardTitle>

          <Spacer axis={Axis.VERTICAL} size={getSpacing(2)} />

          <Styled.DivCardContent>{item.resumeCreatingFlowCardContent}</Styled.DivCardContent>
        </Styled.DivResumeCreatingFlowCard>
      ))}
    </>
  );
};
