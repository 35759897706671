import { CANONICAL_URL } from '@/consts/canonical-url';

export type JsonLd = {
  '@context': string;
  '@type': string;
  name: string;
  url: string;
  description?: string | ArticleJsonLdData['description'];
  about?: Thing;
  accountablePerson?: Person;
  publisher?: Organization;
  copyrightHolder?: CopyrightHolder;
  headline?: ArticleJsonLdData['headline'];
  mainEntityOfPage?: ArticleJsonLdData['mainEntityOfPage'];
  image?: string | ArticleJsonLdData['image'];
  dateModified?: ArticleJsonLdData['dateModified'];
  author?: Person;
  editor?: Editor;
};

type ArticleJsonLdData = {
  headline: string;
  mainEntityOfPage: string;
  description?: string;
  dateModified: string;
  image: string;
};

type Thing = {
  '@type': string;
  name: string;
};

type Person = {
  '@type': string;
  name: string;
  url?: string;
  jobTitle: string;
  description: string;
  image: null;
  sameAs: string;
};

type Organization = {
  '@type': string;
  name: string;
  alternateName: string;
  url: string;
  logo: string;
  sameAs: string;
  publishingPrinciples: string;
  knowsAbout: string;
  ethicsPolicy: string;
  foundingDate: string;
  founder: Person;
  address: PostalAddress;
};

type PostalAddress = {
  '@type': string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  streetAddress: string;
  url: string;
};

type CopyrightHolder = {
  '@type': string;
  name: string;
  alternateName: string;
};

type Editor = {
  '@type': 'Person';
  name: string;
  image: string;
  jobTitle: string;
  description: string;
  sameAs: string;
  url?: string;
};

const MEDIA = {
  NAME: '履歴書の書き方を徹底解説！テンプレートのダウンロードもできる｜らくらく履歴書',
  DESCRIPTION:
    '履歴書は、応募者の学歴・職歴をはじめ、志望動機や自己PR、本人の希望を確認するための、選考のうえで重要となる書類です。このウェブサイトでは、履歴書の書き方や、封筒・送付状の書き方、職務経歴書についてもわかりやすく解説しています。また、履歴書のテンプレートをダウンロードできます。',
  NAME_ABOUT: '履歴書の書き方をわかりやすく解説するメディア',
};

const TOP = {
  NAME: 'らくらく履歴書｜スマホで簡単に履歴書を無料で作成',
  DESCRIPTION:
    '履歴書は、応募者の学歴・職歴をはじめ、志望動機や自己PR、本人の希望を確認するための、選考のうえで重要となる書類です。このウェブサイトでは、スマホやパソコンで簡単に履歴書を作成することが可能です。',
  NAME_ABOUT: '履歴書作成Webアプリケーション',
};

const COPYRIGHT_HOLDER = {
  '@type': 'Organization',
  NAME: 'Ann株式会社',
  ALTERNATE_NAME: 'Ann Inc.',
};

const COMMON_DATA = {
  ACCOUNTABLE_PERSON: {
    '@type': 'Person',
    name: '山田元太郎',
    url: 'https://resume.careermine.jp/supervisor',
    jobTitle: 'Ann株式会社 代表取締役社長CEO',
    description:
      '1990年生まれ。大学卒業後、東証一部上場のメーカーに入社。その後サイバーエージェントにて広告代理事業に従事。現在はサイバーエージェントで培ったWEBマーケの知見を活かしつつ、CareerMineの責任者として就活生に役立つ情報を発信している。また自身の経験を活かし、学生への就職アドバイスを行っている。延べ1,000人以上の学生と面談を行い、さまざまな企業への内定に導いている。',
    image: null,
    sameAs: 'https://resume.careermine.jp/supervisor',
  },
  PUBLISHER: {
    '@type': 'Organization',
    name: 'Ann株式会社',
    alternateName: 'Ann Inc.',
    url: 'https://ann.inc/',
    logo: 'https://storage.googleapis.com/studio-design-asset-files/projects/ogO01305q2/s-1739x523_v-fms_webp_2c271d27-5142-41d2-8ac5-48795d7e575d_small.png',
    sameAs: 'https://ann.inc/company',
    publishingPrinciples: 'https://resume.careermine.jp/edit-policy',
    knowsAbout: 'Ann株式会社は就活生や転職活動中の方に対してサービス提供を行います',
    ethicsPolicy: 'https://ann.inc/company',
    foundingDate: '2020-02-10T00:00:00+09:00',
    founder: {
      '@type': 'Person',
      name: '山田元太郎',
      jobTitle: 'Ann株式会社 代表取締役社長CEO',
      description:
        '1990年生まれ。大学卒業後、東証一部上場のメーカーに入社。その後サイバーエージェントにて広告代理事業に従事。現在はサイバーエージェントで培ったWEBマーケの知見を活かしつつ、CareerMineの責任者として就活生に役立つ情報を発信している。また自身の経験を活かし、学生への就職アドバイスを行っている。延べ1,000人以上の学生と面談を行い、さまざまな企業への内定に導いている。',
      image: null,
      sameAs: 'https://resume.careermine.jp/supervisor',
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Tokyo',
      addressRegion: 'JP',
      postalCode: '1600022',
      streetAddress: '東京都新宿区新宿1丁目36-2 第七葉山ビル 3F',
      url: 'https://ann.inc/contact',
    },
  },
};

const EDITOR_JSON_LD: Editor = {
  '@type': 'Person',
  name: 'Yuka',
  image: 'https://resume.careermine.jp/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Feditor.6ad04161.png&w=256&q=75',
  jobTitle: '編集者',
  description:
    '2021年7月に入社し、CareerMine、SPI対策問題集をはじめとする就活メディアの編集を手掛ける。 以前は広告代理店でメディアプランナーとして、広告やキャンペーンの企画を担当。 『らくらく履歴書』では掲載している記事のチェック、編集、ライター管理、コンテンツ制作などを行っている。また自身もライターとして記事執筆も担当。',
  sameAs: 'https://www.careermine.jp/editor',
};

export const MEDIA_TOP_JSON_LD: JsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: MEDIA.NAME,
  url: CANONICAL_URL.MEDIA.MEDIA_TOP,
  description: MEDIA.DESCRIPTION,
  about: {
    '@type': 'Thing',
    name: MEDIA.NAME_ABOUT,
  },
  accountablePerson: COMMON_DATA.ACCOUNTABLE_PERSON,
  publisher: COMMON_DATA.PUBLISHER,
};

export const TOP_JSON_LD: JsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: TOP.NAME,
  url: CANONICAL_URL.TOP,
  description: TOP.DESCRIPTION,
  about: {
    '@type': 'Thing',
    name: TOP.NAME_ABOUT,
  },
  accountablePerson: COMMON_DATA.ACCOUNTABLE_PERSON,
  publisher: COMMON_DATA.PUBLISHER,
};
export const PAGE_JSON_LD: JsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: TOP.NAME,
  url: CANONICAL_URL.TOP,
  description: TOP.DESCRIPTION,
  about: {
    '@type': 'Thing',
    name: TOP.NAME_ABOUT,
  },
  accountablePerson: COMMON_DATA.ACCOUNTABLE_PERSON,
  publisher: COMMON_DATA.PUBLISHER,
  copyrightHolder: {
    '@type': 'Organization',
    name: COPYRIGHT_HOLDER.NAME,
    alternateName: COPYRIGHT_HOLDER.ALTERNATE_NAME,
  },
};

export const CONTACT_JSON_LD: JsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'お問い合せ',
  url: CANONICAL_URL.CONTACT,
  description: TOP.DESCRIPTION,
  about: {
    '@type': 'Thing',
    name: TOP.NAME_ABOUT,
  },
  accountablePerson: COMMON_DATA.ACCOUNTABLE_PERSON,
  publisher: COMMON_DATA.PUBLISHER,
  copyrightHolder: {
    '@type': 'Organization',
    name: COPYRIGHT_HOLDER.NAME,
    alternateName: COPYRIGHT_HOLDER.ALTERNATE_NAME,
  },
};

export const ARTICLE_JSON_LD = (articleJsonLdData: ArticleJsonLdData): JsonLd => ({
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: TOP.NAME,
  url: CANONICAL_URL.TOP,
  description: articleJsonLdData.description,
  headline: articleJsonLdData.headline,
  image: articleJsonLdData.image,
  mainEntityOfPage: articleJsonLdData.mainEntityOfPage,
  dateModified: articleJsonLdData.dateModified,
  accountablePerson: COMMON_DATA.ACCOUNTABLE_PERSON,
  editor: EDITOR_JSON_LD,
  author: COMMON_DATA.ACCOUNTABLE_PERSON,
  publisher: COMMON_DATA.PUBLISHER,
});
