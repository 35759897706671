import styled from '@emotion/styled';

import { COLOR } from '@/styles/color';
import { FONT_SIZE } from '@/styles/font-size';
import { mq } from '@/styles/media';
import { Z_INDEX } from '@/styles/z-index';

export const DivPositionRoot = styled.div`
  position: relative;
`;

export const DivOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: ${Z_INDEX.IMAGE_OVERLAY};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.58) 100%);
`;

export const DivListRoot = styled.div`
  position: absolute;
  left: 16px;
  bottom: 24px;
  z-index: ${Z_INDEX.IMAGE_OVERLAY_ON_ELEMENT};
  color: ${COLOR.WHITE};
`;

export const DivListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  ${mq.sp} {
    gap: 8px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const SpanText = styled.span`
  font-size: ${FONT_SIZE.SIZE_20};

  ${mq.sp} {
    font-size: ${FONT_SIZE.SIZE_12};
  }
`;

export const DivBackgroundImage = styled.div<{ packageImageUrl: string; paddingTop: number }>`
  background-image: url(${({ packageImageUrl }) => packageImageUrl});
  background-color: lightgray;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: ${({ paddingTop }) => paddingTop}%;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
