import { forwardRef, memo } from 'react';

import type { Ref, SVGProps } from 'react';

const Paper = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width='55' height='80' viewBox='0 0 55 80' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M51.2193 3.97787H3.73095C2.63798 3.97787 1.75195 4.94501 1.75195 6.13804V76.1335C1.75195 77.3266 2.63798 78.2937 3.73095 78.2937H51.2193C52.3122 78.2937 53.1982 77.3266 53.1982 76.1335V6.13804C53.1982 4.94501 52.3122 3.97787 51.2193 3.97787Z'
      fill='white'
    />
    <path
      d='M51.2195 2.57444H4.0216C3.56559 2.54744 3.1093 2.62175 2.68036 2.79285C2.25141 2.96396 1.85874 3.22831 1.52612 3.56989C1.19351 3.91148 0.927859 4.32319 0.745261 4.78011C0.562662 5.23703 0.466908 5.72964 0.463809 6.22813C0.44613 6.96085 0.463809 7.69709 0.463809 8.4304V69.7905C0.463809 71.8957 0.393628 74.0138 0.463809 76.1166C0.4771 77.0589 0.824933 77.9588 1.43402 78.6267C2.04311 79.2946 2.8659 79.6784 3.72909 79.6972C3.90374 79.7048 4.07947 79.6972 4.25412 79.6972H49.7747C50.8199 79.6972 51.9224 79.8142 52.8648 79.2107C54.2437 78.3288 54.4816 76.8096 54.4816 75.2576V6.68952C54.4816 6.51408 54.4875 6.33455 54.4816 6.15737C54.4683 5.21505 54.1207 4.31509 53.5119 3.64684C52.9031 2.97859 52.0806 2.59416 51.2174 2.57444C49.5625 2.49315 49.5663 5.30067 51.2174 5.38137C52.066 5.42289 51.9101 6.44449 51.9101 7.12517V75.3102C51.9101 75.9909 52.0258 76.8107 51.2094 76.8891C50.5526 76.9523 49.8641 76.8891 49.2057 76.8891H4.16679C3.54855 76.8891 3.05943 76.7681 3.03746 75.9733C3.0155 75.1786 3.03746 74.3757 3.03746 73.5793V12.9291C3.03746 10.697 2.96246 8.44735 3.03746 6.21643C3.068 5.32464 3.73391 5.38137 4.35376 5.38137H51.2195C52.8739 5.38137 52.8766 2.57444 51.2195 2.57444Z'
      fill='#211715'
    />
    <path
      d='M16.1045 8.37716C16.1045 7.23977 16.1045 4.01999 16.1104 2.69488C16.1111 2.36106 16.2331 2.04118 16.4497 1.80547C16.6662 1.56975 16.9596 1.43745 17.2654 1.43761H37.6821C37.9879 1.43745 38.2813 1.56965 38.4979 1.80523C38.7145 2.0408 38.8367 2.36052 38.8377 2.69429C38.8425 4.0194 38.8463 7.24035 38.8458 8.37891C38.8458 8.54445 38.8159 8.70837 38.7578 8.86131C38.6998 9.01424 38.6147 9.15318 38.5074 9.27021C38.4002 9.38724 38.2728 9.48006 38.1327 9.54335C37.9926 9.60665 37.8424 9.63919 37.6907 9.63911H17.2595C17.1077 9.63919 16.9574 9.60659 16.8172 9.54318C16.677 9.47978 16.5495 9.38681 16.4423 9.26959C16.335 9.15238 16.2499 9.01323 16.192 8.86009C16.134 8.70696 16.1043 8.54285 16.1045 8.37716Z'
      fill='#048573'
    />
    <path
      d='M17.3904 8.37716C17.3904 7.38849 17.3904 6.39944 17.3904 5.41C17.3904 4.84627 17.3904 4.28274 17.3904 3.71941C17.4199 3.38666 17.4246 3.05184 17.4043 2.71827C17.5281 3.12761 17.0909 2.70599 17.3508 2.84107C17.4929 2.86336 17.6372 2.86336 17.7793 2.84107H21.3393H33.4629H37.0828C37.2192 2.85277 37.3562 2.85277 37.4927 2.84107C37.8918 2.76739 37.4852 2.95802 37.5403 2.71768C37.5248 3.05157 37.5296 3.38622 37.5548 3.71941C37.5548 4.28274 37.5559 4.84627 37.558 5.41V7.02282C37.5098 7.47332 37.5098 7.92841 37.558 8.37891C37.5087 8.18535 37.8795 8.23564 37.385 8.23564H17.7499C17.6015 8.23564 17.4493 8.24558 17.3009 8.23564C17.1981 8.22862 17.3813 8.25084 17.3899 8.37891C17.3818 8.00944 17.2438 7.65753 17.0044 7.39621C16.765 7.13488 16.4426 6.98421 16.1041 6.97545C15.764 6.97865 15.4387 7.12754 15.1982 7.39005C14.9577 7.65256 14.8213 8.00768 14.8184 8.37891C14.8321 8.75282 14.9148 9.11988 15.0614 9.45813C15.2081 9.79637 15.4157 10.0989 15.672 10.3475C15.9282 10.5962 16.2277 10.7859 16.5526 10.9053C16.8775 11.0247 17.2211 11.0714 17.5629 11.0426H35.946C36.7534 11.1202 37.5665 11.0854 38.3659 10.9391C39.8188 10.5081 40.1338 9.02743 40.1338 7.60584C40.1338 6.3396 40.1322 5.07375 40.129 3.80829C40.1617 3.25055 40.1409 2.6906 40.0669 2.13759C39.9778 1.69857 39.7887 1.29116 39.5171 0.95304C39.2456 0.614919 38.9003 0.357006 38.5132 0.203143C37.7257 -0.105619 36.7325 0.0364795 35.9031 0.0364795H22.4466H19.1567C18.2042 0.0364795 16.9977 -0.156497 16.133 0.345242C14.6737 1.19258 14.8264 2.91008 14.8243 4.44395C14.8221 5.75619 14.8221 7.06823 14.8243 8.38008C14.8205 10.1835 17.392 10.1865 17.3904 8.37716Z'
      fill='#211715'
    />
    <path
      d='M13.084 27.4438H41.8661C43.521 27.4438 43.5237 24.6369 41.8661 24.6369H41.0684C39.4141 24.6369 39.4114 27.4438 41.0684 27.4438H41.8661C43.521 27.4438 43.5237 24.6369 41.8661 24.6369H13.084C11.4291 24.6369 11.4264 27.4438 13.084 27.4438Z'
      fill='#211715'
    />
    <path
      d='M13.084 38.6055H41.8661C43.521 38.6055 43.5237 35.7986 41.8661 35.7986H41.0684C39.4141 35.7986 39.4114 38.6055 41.0684 38.6055H41.8661C43.521 38.6055 43.5237 35.7986 41.8661 35.7986H13.084C11.4291 35.7986 11.4264 38.6055 13.084 38.6055Z'
      fill='#211715'
    />
    <path
      d='M13.084 49.7671H41.8661C43.521 49.7671 43.5237 46.9602 41.8661 46.9602H41.0684C39.4141 46.9602 39.4114 49.7671 41.0684 49.7671H41.8661C43.521 49.7671 43.5237 46.9602 41.8661 46.9602H13.084C11.4291 46.9602 11.4264 49.7671 13.084 49.7671Z'
      fill='#211715'
    />
    <path
      d='M13.084 60.9294H41.8661C43.521 60.9294 43.5237 58.1224 41.8661 58.1224H41.0684C39.4141 58.1224 39.4114 60.9294 41.0684 60.9294H41.8661C43.521 60.9294 43.5237 58.1224 41.8661 58.1224H13.084C11.4291 58.1224 11.4264 60.9294 13.084 60.9294Z'
      fill='#211715'
    />
  </svg>
);

export const IconPaper = memo(forwardRef(Paper));
