import { memo } from 'react';

import { IconCheckCircle } from '@/components/icons/check-circle';

import * as Styled from './style';

type PackageImageProps = {
  /** 画像のURL */
  packageImageUrl: string;
  /** 画像の上に表示させる3つのテキスト */
  threeText: string[];
  /**
   * Overlay を当てるかどうか
   * @default true
   */
  isOverlay?: boolean;
  /**
   * 背景画像のアスペクト比率
   * @default 56.25
   */
  paddingTop?: number;
};

const _PackageImage = ({ packageImageUrl, threeText, isOverlay = true, paddingTop = 56.25 }: PackageImageProps) => (
  <Styled.DivPositionRoot>
    {isOverlay && <Styled.DivOverlay />}

    <Styled.DivListRoot>
      {threeText.map((text, index) => (
        <Styled.DivListItem key={`${text}-${index}`}>
          <IconCheckCircle width={24} height={24} />
          <Styled.SpanText>{text}</Styled.SpanText>
        </Styled.DivListItem>
      ))}
    </Styled.DivListRoot>

    <Styled.DivBackgroundImage
      role='img'
      aria-label='パッケージ画像'
      packageImageUrl={packageImageUrl}
      paddingTop={paddingTop}
    />
  </Styled.DivPositionRoot>
);

/**
 * アスペクト比が「9:16」のパッケージ画像を全画面で表示するために使用するコンポーネント
 * @param props {@link PackageImageProps}
 */
export const PackageImage = memo(_PackageImage);
