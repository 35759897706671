/**
 * DOMのIDを渡し、その要素のあるところまで画面をスクロールさせる関数
 */
export const smoothScrollToElementById = (target: string, yOffset: number = 0) => {
  const targetElement = document.getElementById(target);

  if (targetElement == null) return;

  const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({
    top: y,
    behavior: 'smooth',
  });
};
