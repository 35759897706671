/**
 * ### 重複コンテンツを防ぎURLを正規化するために使用する定数
 * - Next.js では末尾にスラッシュが付いているURLから、末尾にスラッシュが付いていないURLにデフォルトでリダイレクトするので末尾にスラッシュは付けない
 */
export const CANONICAL_URL = {
  /** トップページ */
  TOP: 'https://resume.careermine.jp',
  /** 利用規約ページ */
  TERMS: 'https://resume.careermine.jp/terms',
  /* プライバシーポリシー */
  PRIVACY_POLICY: 'https://resume.careermine.jp/privacypolicy',
  /** マイページ */
  MY_PAGE: 'https://resume.careermine.jp/my-page',
  /** マイページ > 退会手続きページ */
  MY_PAGE_WITHDRAW: 'https://resume.careermine.jp/my-page/withdrawal',
  /** よくある質問ページ */
  FAQ: ' https://resume.careermine.jp/faq',
  /* 監修者メッセージページ */
  SUPERVISOR: 'https://resume.careermine.jp/supervisor',
  /* お問い合わせページ */
  CONTACT: 'https://resume.careermine.jp/contact',
  /* 履歴書使い方 */
  HOW_TO_USE: 'https://resume.careermine.jp/how-to-use',
  /** 編集ポリシー */
  EDIT_POLICY: 'https://resume.careermine.jp/edit-policy',
  /** 職務履歴書作成ページ */
  CV: {
    /** 1. プロフィール入力ページ */
    PROFILE: 'https://resume.careermine.jp/cv/profile',
    /** 2. 職務要約ページ */
    SUMMARY: 'https://resume.careermine.jp/cv/summary',
    /** 3. スキルページ */
    SKILL: 'https://resume.careermine.jp/cv/skill',
    /** 4. 職歴ページ */
    HISTORY: 'https://resume.careermine.jp/cv/history',
    /** 5. 自己PR入力ページ */
    SELF_PROMOTION: 'https://resume.careermine.jp/cv/self-promotion',
    /** 6. 職務経歴書作成ページ */
    CREATE_DATE: 'https://resume.careermine.jp/cv/create-date',
    /** 7. 職務経歴書完成ページ */
    COMPLETE: 'https://resume.careermine.jp/cv/complete',
    /** 8. 職務経歴書をダウンロードするページ */
    ACTION: 'https://resume.careermine.jp/cv/action',
    /** 9. アクション > 印刷設定ページ */
    ACTION_PRINT_CONFIG: 'https://resume.careermine.jp/cv/action/print-config',
    /** 10. アクション > 印刷準備完了ページ */
    ACTION_PRINT_COMPLETE: 'https://resume.careermine.jp/cv/action/print-complete',
    /** 10. アクション > 履歴書PDF送信ページ */
    ACTION_SEND_PDF: 'https://resume.careermine.jp/cv/action/send-pdf',
  },
  /** JIS規格の履歴書作成ページ */
  RESUME_COMMON: {
    /** アクションページ */
    ACTION: 'https://resume.careermine.jp/resume-common/action',
    /** アクション > 印刷準備 > 履歴書の確認 */
    ACTION_PRINT_CONFIRM: 'https://resume.careermine.jp/resume-common/action/print-confirm',
    /** アクション > 印刷準備 > ユーザー番号発行 */
    ACTION_PRINT_CONFIG: 'https://resume.careermine.jp/resume-common/action/print-config',
    /** アクション > 印刷準備完了ページ */
    ACTION_PRINT_COMPLETE: 'https://resume.careermine.jp/resume-common/action/print-complete',
    /** アクション > 履歴書PDF送信ページ */
    ACTION_SEND_PDF: 'https://resume.careermine.jp/resume-common/action/send-pdf',
    /** 住所入力ページ */
    ADDRESS: 'https://resume.careermine.jp/resume-common/address',
    /** 通勤時間等入力ページ */
    COMMUTE: 'https://resume.careermine.jp/resume-common/commute',
    /** 履歴書完成ページ */
    COMPLETE: 'https://resume.careermine.jp/resume-common/complete',
    /** 連絡先入力ページ */
    CONTACT: 'https://resume.careermine.jp/resume-common/contact',
    /** 学歴入力ページ */
    EDUCATION: 'https://resume.careermine.jp/resume-common/education',
    /** 職歴入力ページ */
    CAREER: 'https://resume.careermine.jp/resume-common/career',
    /** 証明写真入力ページ */
    ID_PICTURE: 'https://resume.careermine.jp/resume-common/id-picture',
    /** 資格・免許入力ページ */
    LICENSE: 'https://resume.careermine.jp/resume-common/license',
    /** プロフィール入力ページ */
    PROFILE: 'https://resume.careermine.jp/resume-common/profile',
    /** 本人希望欄入力ページ */
    SELF_HOPE: 'https://resume.careermine.jp/resume-common/self-hope',
    /** 自己PR入力ページ */
    SELF_PROMOTION: 'https://resume.careermine.jp/resume-common/self-promotion',
    /** 作成日入力ページ */
    CREATE_DATE: 'https://resume.careermine.jp/resume-common/create-date',
  },
  MEDIA: {
    /** メディアTOP */
    MEDIA_TOP: 'https://resume.careermine.jp/media',
    /** カテゴリページ */
    MEDIA_CATEGORIES: 'https://resume.careermine.jp/media/categories',
    /** タグページ */
    MEDIA_TAGS: 'https://resume.careermine.jp/media/tags',
    /** 記事ページ */
    MEDIA_ARTICLES: 'https://resume.careermine.jp/media/articles',
    /** 検索結果ページ */
    MEDIA_SEARCH: 'https://resume.careermine.jp/media/search',
  },
  ACCOUNT: {
    /**　サインインページ */
    SIGN_IN: 'https://resume.careermine.jp/account/signin',
    /**　サインナップページ */
    SIGN_UP: 'https://resume.careermine.jp/account/signup',
  },
} as const;
