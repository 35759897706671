import { forwardRef, memo } from 'react';

import type { Ref, SVGProps } from 'react';

const MagnifyingGlass = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width='66' height='72' viewBox='0 0 66 72' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M63.0852 61.7191L44.5981 41.5396C42.892 44.2875 40.7139 46.6482 38.1846 48.4911L56.6926 68.6936C57.0983 69.1833 57.5928 69.5755 58.1449 69.8453C58.6969 70.115 59.2944 70.2564 59.8992 70.2604C60.504 70.2644 61.103 70.1309 61.658 69.8685C62.213 69.6061 62.7119 69.2204 63.1229 68.7361C65.0582 66.6311 64.9889 63.7992 63.0852 61.7191Z'
      fill='white'
    />
    <path
      d='M42.4431 41.4802L60.6568 61.3614C61.9088 62.7281 63.713 64.2844 62.8264 66.4508C62.6008 67.0005 62.259 67.484 61.8291 67.8617C61.3992 68.2393 60.8934 68.5003 60.3532 68.6234C59.8268 68.6737 59.297 68.584 58.8093 68.3618C58.3215 68.1396 57.8905 67.7917 57.553 67.3478C54.5153 64.0529 51.4974 60.7372 48.469 57.4322L38.1144 46.1289C36.7622 44.6529 34.663 46.9429 36.0159 48.4196C42.5271 55.5269 48.992 62.689 55.554 69.7464C57.8485 72.2138 61.1805 72.5992 63.726 70.3282C64.9251 69.3053 65.7132 67.8138 65.924 66.1687C66.1348 64.5235 65.7517 62.8533 64.8556 61.5106C63.6599 59.7484 62.0041 58.2522 60.5857 56.704L55.4062 51.0497L44.5415 39.1896C43.1893 37.7136 41.0902 40.0036 42.4431 41.4802Z'
      fill='#211715'
    />
    <path
      d='M25.0671 52.8335C38.0845 52.8335 48.6371 41.3564 48.6371 27.1987C48.6371 13.0411 38.0845 1.564 25.0671 1.564C12.0497 1.564 1.49707 13.0411 1.49707 27.1987C1.49707 41.3564 12.0497 52.8335 25.0671 52.8335Z'
      fill='#048573'
    />
    <path
      d='M25.0673 45.288C34.2534 45.288 41.7001 37.1892 41.7001 27.1987C41.7001 17.2082 34.2534 9.10934 25.0673 9.10934C15.8813 9.10934 8.43457 17.2082 8.43457 27.1987C8.43457 37.1892 15.8813 45.288 25.0673 45.288Z'
      fill='white'
    />
    <path
      d='M40.6848 10.2182C47.0786 17.2372 49.0318 28.1807 45.1551 37.2142C43.2341 41.7454 40.0736 45.5153 36.1086 48.0048C32.1436 50.4944 27.5692 51.5811 23.015 51.1155C18.4608 50.65 14.1508 48.6549 10.6783 45.405C7.20576 42.1551 4.74152 37.8102 3.62478 32.9684C2.56014 28.167 2.86892 23.1247 4.50954 18.5207C6.15016 13.9166 9.04384 9.97186 12.8009 7.2176C16.5736 4.52412 21.0029 3.13561 25.51 3.2335C30.0171 3.33139 34.3923 4.91114 38.0638 7.76629C38.9946 8.50711 39.871 9.32624 40.6848 10.2162C42.045 11.6821 44.1472 9.3928 42.7833 7.92152C39.1161 3.99955 34.4462 1.38265 29.3892 0.415788C24.3322 -0.551075 19.1255 0.177492 14.4555 2.50545C9.77305 4.8799 5.88093 8.78169 3.31591 13.6727C0.750891 18.5637 -0.360656 24.203 0.134577 29.8127C0.62981 35.4224 2.70742 40.7262 6.08087 44.9926C9.45431 49.259 13.9574 52.2777 18.969 53.6325C23.9904 54.9618 29.264 54.5858 34.0842 52.5546C38.9045 50.5234 43.0416 46.934 45.9422 42.2665C48.7367 37.6395 50.1783 32.1953 50.0761 26.6544C49.9738 21.1135 48.3326 15.737 45.3696 11.2366C44.5835 10.0661 43.719 8.96074 42.7833 7.92962C41.4348 6.44484 39.3363 8.73949 40.6848 10.2182Z'
      fill='#211715'
    />
    <path
      d='M35.7798 15.556C37.9486 17.9366 39.4039 20.98 39.9531 24.2834C40.5023 27.5868 40.1194 30.9944 38.8552 34.055C37.54 37.1698 35.3715 39.7622 32.6489 41.4742C29.9264 43.1863 26.784 43.9337 23.6558 43.6131C20.5275 43.2925 17.5675 41.9198 15.1844 39.6845C12.8014 37.4492 11.1128 34.4616 10.3517 31.134C9.62546 27.8418 9.84096 24.3857 10.9692 21.2313C12.0975 18.0768 14.0843 15.3756 16.6621 13.4914C19.5885 11.4103 23.0776 10.4806 26.5533 10.8559C30.0291 11.2313 33.2837 12.8892 35.7798 15.556C37.14 17.0185 39.2422 14.7299 37.8783 13.2653C35.2082 10.4127 31.8051 8.51606 28.1225 7.82806C24.4399 7.14005 20.6527 7.69341 17.2655 9.41437C13.9035 11.1556 11.1169 13.9891 9.28781 17.5266C7.45869 21.064 6.67646 25.1323 7.04832 29.174C7.42018 33.2157 8.92794 37.0329 11.365 40.1027C13.802 43.1725 17.0491 45.3446 20.6612 46.3213C24.2788 47.2763 28.0769 47.0082 31.5517 45.5528C35.0266 44.0973 38.014 41.5232 40.1177 38.1719C44.9466 30.4557 43.846 19.8436 37.8776 13.2647C36.5316 11.7765 34.4331 14.0712 35.7798 15.556Z'
      fill='#211715'
    />
  </svg>
);

export const IconMagnifyingGlass = memo(forwardRef(MagnifyingGlass));
