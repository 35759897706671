import dynamic from 'next/dynamic';
import { memo } from 'react';

import { Footer } from '@/components/layouts/common/footer';
import { PreviewModal } from '@/components/modals/preview-modal';
import { ShokumuKeirekiPreviewModal } from '@/components/modals/shokumukeireki-preview-modal';
import { useFillScreenHeight } from '@/hooks/use-fill-screen-height';

import type { Category } from '@/mediaApi/types';
import type { ReactNode } from 'react';

import { Header } from '../header';
import * as Styled from '../style';

const AuthModal = dynamic<Record<string, unknown>>(
  async () => await import('@/components/modals/auth-modal').then((module) => module.AuthModal),
  { ssr: false },
);

const ROOT_LAYOUT_CONTAINER_ID = 'layout-container';

type LayoutProps = {
  /** mainタグのchildren */
  children: ReactNode;
  /** MainとHeaderの境にborderを表示するかどうか（デフォルトは`false`） */
  isDividerVisible?: boolean;
  categories: Category[];
};

export const MainLayoutWithFooter = memo(({ children, isDividerVisible = false, categories }: LayoutProps) => {
  useFillScreenHeight();

  return (
    <>
      <Styled.DivLayoutContainer id={ROOT_LAYOUT_CONTAINER_ID}>
        <Header />
        <Styled.Divider visible={isDividerVisible} />
        <Styled.Main>{children}</Styled.Main>
        <Footer categories={categories} />
      </Styled.DivLayoutContainer>

      <AuthModal />
      <PreviewModal />
      <ShokumuKeirekiPreviewModal />
    </>
  );
});

MainLayoutWithFooter.displayName = 'MainLayoutWithFooter';
