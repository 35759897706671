import Link from 'next/link';
import { useState } from 'react';

import { Spacer } from '@/components/atoms/spacer';
import { PackageImage } from '@/components/templates/top-template/resume-card/package-image';
import { Axis } from '@/consts/axis';
import { getSpacing } from '@/consts/spacingUnit';

import type { ResumeCardInfo } from '../type';

import * as Styled from './style';

type ResumeCardProps = {
  resumeCardInfo: ResumeCardInfo[];
};

type FilterType = 'all' | 'resume' | 'cv';

export const ResumeCard = ({ resumeCardInfo }: ResumeCardProps) => {
  const [filter, setFilter] = useState('all');
  const handleFilterChange = (filterType: FilterType) => {
    setFilter(filterType);
  };

  const filterdResumeCardInfo = (resumeCardInfo: ResumeCardInfo[]) => {
    switch (filter) {
      case 'resume':
        return getResumeTypeInfo(resumeCardInfo);
      case 'cv':
        return getCvTypeInfo(resumeCardInfo);
      case 'all':
      default:
        return resumeCardInfo;
    }
  };

  const getCvTypeInfo = (resume_cards: ResumeCardInfo[]) =>
    resume_cards.filter((resume_card) => resume_card.type === 'cv');

  const getResumeTypeInfo = (resume_cards: ResumeCardInfo[]) =>
    resume_cards.filter((resume_card) => resume_card.type === 'resume');

  return (
    <>
      <Spacer axis={Axis.VERTICAL} size={getSpacing(2)} />

      <Styled.DivButtonsContainer>
        <Styled.ButtonFilterItem className={filter === 'all' ? 'active' : ''} onClick={() => handleFilterChange('all')}>
          すべて
        </Styled.ButtonFilterItem>
        <Styled.ButtonFilterItem
          className={filter === 'resume' ? 'active' : ''}
          onClick={() => handleFilterChange('resume')}
        >
          履歴書
        </Styled.ButtonFilterItem>
        <Styled.ButtonFilterItem className={filter === 'cv' ? 'active' : ''} onClick={() => handleFilterChange('cv')}>
          職務経歴書
        </Styled.ButtonFilterItem>
      </Styled.DivButtonsContainer>

      <Spacer axis={Axis.VERTICAL} size={getSpacing(2)} />

      <Styled.DivRootPadding>
        {filterdResumeCardInfo(resumeCardInfo).map((item, index) => (
          <Styled.ArtRootResumeCard key={`${item.resumeTitle}-${index}`}>
            <PackageImage
              packageImageUrl={item.resumePackageImageUrl}
              threeText={item.resumeThreeText}
              paddingTop={96}
            />

            <Styled.SecContentWrapper>
              <Styled.ParTitle>{item.resumeTitle}</Styled.ParTitle>

              <Styled.ParDescription>{item.resumeDescription}</Styled.ParDescription>
              <Link href={item.resumeFormatUrl} passHref>
                <Styled.ButtonSelect type={item.type}>
                  <Styled.ButtonText>これを選ぶ</Styled.ButtonText>
                </Styled.ButtonSelect>
              </Link>
            </Styled.SecContentWrapper>
          </Styled.ArtRootResumeCard>
        ))}
      </Styled.DivRootPadding>
    </>
  );
};
